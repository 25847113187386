// bg-*
// =========================================================================
&:not(.home) {
	.main {
		section {
			&:not(.eyecatcher) {
				padding: 3vh 0 !important;
			}
		}
	}
}


section {
	position: relative;

	&:not(.bg-transparent) {
		background: $green-dark;

		p,
		li {
			color: $blue-text;
		}
	}

	&.bg-transparent:not(.mini-sab) {
		padding: 35px 0 0;
	}

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	padding: 6vh 0 0 !important;
	margin: 0;
	background: $green-dark;

	.container-one-column {
		.container-holder {
			margin: 0 auto;
			text-align: center;
			@media(max-width: 991px) {
				text-align: left;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		display: flex;
		flex-flow: row wrap;

		> * {
			flex: 0 0 100%;
			max-width: 100%;
		}

		.title {
			order: -1;
		}

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}

	.column.one > * {
		max-width: 625px;
	}

	.properties {
		color: $blue;
	}
}

// content-section
// =========================================================================
.content-section {
	padding: 6vh 0;
	margin: 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.container-one-column {
		.col-8 {
			.wysiwyg {
				max-width: 770px;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

.big-card-section {
	padding-top: 45px;
	padding-bottom: 45px;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 6vh 0;
	margin: 0;
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 6vh 0;
	margin: 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	padding: 6vh 0 0;
	margin: 0;
}

// booking-section
// =========================================================================
.booking-section {
	padding: 6vh 0;
	margin: 0;

	h2 {
		margin-bottom: 30px;
	}
}

