@media(min-width: 991px) {
	.collection {
		margin-bottom: 35px;

		&.full-width-blocks {
			.grid-items {
				> .item {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}

		&.two-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 50%;
					max-width: 50%
				}
			}
		}

		&.three-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 33.333%;
					max-width: 33.333%;

					.card-image {
						background: #fff;
						padding: 10px 10px 0;
					}
				}
			}
		}

		&.four-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 25%;
					max-width: 25%;
				}
			}
		}

		&.five-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 20%;
					max-width: 20%;
				}
			}
		}

		&.six-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 16.6666666667%;
					max-width: 16.6666666667%;
					padding: 0 9px;
				}
			}
		}

		&.top-indents {
			.grid-items {
				> .item {
					&:nth-of-type(2n + 2) {
						margin-top: 15px;
					}
				}
			}
		}

		&.small-cards {
			.grid-items {
				> .item {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}
}

.small-cards {
	.grid-items {
		> .item {
			margin-bottom: 15px;
			flex: 0 0 100% !important;
			max-width: 100% !important;

			.card-image,
			.card-buttons {
				display: none;
			}

			.card-title {
				color: $blue;
				font-size: $h4-font-size;
				@extend .arrow-right;
			}

			.card-description-content {
				font-size: $font-size-med;
			}
		}
	}
}

.homepage-icons {
	.item {
		.card {
			background: #D6EADF;
			border: none;
			border-radius: 50%;
			max-width: 273px;
			min-height: 273px;
			margin-left: auto;
			margin-right: auto;
			overflow: visible;

			.card-image {
				padding-top: 25px;
			}

			.card-caption {
				.card-title {
					font-size: $h4-font-size;
					color: $blue;
					text-align: center;
					margin-top: 20px;
				}
			}
		}
	}
}

.homepage-big-cards {
	padding-bottom: 30px;
	.item {
		@media(max-width: 991px) {
			flex: 0 0 100% !important;
			max-width: 100% !important;
			margin-bottom: 25px !important;
			border-radius: 0;
		}

		.card {
			.card-body {
				position: absolute;

				h3.card-title {
					color: #fff;
					text-shadow: 0 0 20px #00000050;
					@extend .arrow-right;
					max-width: 520px;

					&:after {
						color: #fff;
					}
				}

				.card-buttons {
					display: none;
				}
			}
		}

		&:nth-of-type(2n + 1) {
			.card {
				overflow: visible;
			}

			@media(min-width: 992px) {
				.waves-div {
					content: '';
					background: url('~project/public/images/golf_cards.png');
					position: absolute;
					left: 0;
					bottom: 90px;
					width: inherit;
					z-index: +1;
					line-height: 0;
					height: 35px;
					background-repeat-y: no-repeat;
				}
			}
		}
	}
}

.full-width-blocks {
	.grid-items {
		> .item {
			.card-body {
				order: -1;

				.card-caption {
					display: flex;
					flex-flow: column wrap;
					align-items: flex-start;

					.card-subtitle {
						order: -1;
					}
				}
			}

			.card-image {
				@media(min-width: 768px) {
					flex: 0 0 66.66667%;
					max-width: 66.66667%;
				}
			}
		}
	}
}

.card {
	.card-body {
		padding: 25px 35px;

		.card-caption .card-title {
			color: $blue;

			&:after {
				color: inherit;
			}

			&:hover {
				color: #000;
			}
		}
	}

	.list.list-icons .list-item {
		padding-left: 45px;

		.icon {
			color: $blue;
		}
	}
}
