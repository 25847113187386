// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
	@extend .clearfix;
}

.search-book .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left {
	background: $primary;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
	background: #f8f8f8
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
	display: none;
}

.search-book .tommy-zeb-wrapper * h2 {
	font-size: 18px;
	font-weight: 700;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
	margin-top: 0 !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
	@extend .btn-primary;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
	margin-bottom: 10px;
	color: $primary;
	font-size: 20px;
	font-weight: 700;
}

.search-book .tommy-zeb-accommodation-person-category select {
	width: 60px;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
	color: $secondary;
	font-weight: 700;
	font-size: 40px;
}

@media (min-width: 1200px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		max-width: 100%;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
	width: 540px;
	padding: 15px 0 10px;
	max-width: 100%;
}


@media (min-width: 980px) and (max-width: 1199px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		margin-bottom: 15px;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
		margin-right: 0;
	}
}

.tommy-zeb-results > div {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

@media(min-width: 992px) {
	.tommy-zeb-results > div > div {
		flex: 0 0 calc(50% - 15px);
		max-width: calc(50% - 15px);
	}
}

@media(max-width: 991px) {
	.tommy-zeb-wrapper .tommy-zeb-result-image {
		width: 100%;
		max-width: 100%;
		height: auto;
	}
}
