// footer
.footer {
	background: url(/images/streepje.svg);
	background-attachment: fixed;
	background-color: #fff !important;
	padding: 100px 0 175px;
	position: relative;

	&:after {
		content: url('~project/public/images/Onderkant.svg');
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-size: cover;
		z-index: +1;
		line-height: 0;
		pointer-events: none;
	}

	section {
		background: #fff !important;
		max-width: 1640px;
		margin-left: auto;
		margin-right: auto;
		@media(min-width: 768px) {
			padding: 55px 125px 0;
		}

		&.footer-socket {
			padding-top: 25px;
			padding-bottom: 50px;
		}

		.container {
			&:not(:last-of-type) {
				margin-bottom: 75px;
			}
		}
	}

	.container-four-columns {
		.column {
			z-index: +2;
			position: relative;

			h3 {
				font-size: $h4-font-size;
			}

			p {
				margin-bottom: 0;
				font-size: 1.125rem;
			}
		}
	}

	.footer-socket {
		.footer-link {
			li {
				margin-right: 15px;

				a {
					font-size: $font-size-small;
				}
			}
		}
	}
}

.social-icons li {
	&:not(:last-of-type) {
		margin-right: 15px;
	}

	a {
		font-size: 35px; 
		cursor: pointer;

		&:hover {
			opacity: .7;
		}

		.list-item-title {
			display: none;
		}
	}
}
