// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	align-items: flex-start;
	@media(max-width: 767px) {
		height: 70vh;
		min-height: 70vh;
	}

	.owl-caption {
		text-align: left;
		padding-top: 35px;
		display: flex;
		flex-flow: row wrap;
		@media(min-width: 992px) {
			max-width: 50%;
		}

		.owl-title,
		.owl-subtitle {
			color: #fff;
			flex: 0 0 100%;
			max-width: 100%;
		}

		.owl-subtitle {
			font-size: $font-size-base;
			order: -1;
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	@media(max-width: 1199px) {
		height: auto;
		min-height: auto;
		max-height: none;
	}
	@media(min-width: 1200px) {
		height: 70vh;
		min-height: 540px;
		max-height: 70vh;
	}
}

.eyecatcher.large {
	.owl-stage-outer {
		&:before {
			content: url('~project/public/images/swirl-slider-nieuw.svg');
			position: absolute;
			left: 0;
			bottom: 90px;
			width: 100%;
			z-index: +1;
			line-height: 0;
			@media(max-width: 991px) {
				width: 200%;
			}
		}
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	&:after {
		content: url('~project/public/images/Bovenkant-lead.svg');
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: +1;
		line-height: 0;
		pointer-events: none;
	}

	// vimeo-video
	.vimeo-video {
		position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		/*iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw; // height = 100 * (9 / 16) = 56.25
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh; // width = 100 / (9 / 16) = 177.777777
			}
		}
		*/
	}
}

.owl-carousel.homepage-small-slider {

	background: #fff;
	background-image: url('~project/public/images/grasgroen1.svg');
	background-position: left bottom;
	background-repeat: no-repeat;
	background-size: 39%;

	.owl-stage-outer {
		.item {
			background-position: 33.333vw 0;
			min-height: 675px;
			background-size: contain;
			align-items: flex-start;
			@media(max-width: 1199px) {
				align-items: flex-end;
				background-position: 0 0;
				background-size: 100%;
				padding-bottom: 0;
				//min-height: 90vh;
			}

			.owl-container {
				padding: 0;
			}

			.owl-caption {
				text-align: left;
				display: flex;
				flex-flow: column wrap;
				width: 100%;
				max-width: 100%;
				@media(min-width: 1200px) {
					width: 33.333%;
					max-width: 390px;
					padding-left: 45px;
				}
				@media(max-width: 1199px) {
					background: #fff;
					padding: 15px 25px 45px;
				}

				.owl-title {
					font-size: $h3-font-size;
					max-width: 320px;
				}

				.owl-subtitle {
					order: -1;
					font-size: $h4-font-size;
					font-weight: bolder;
					color: $blue-dark;
					margin-bottom: 35px;
				}

				.owl-description {
					color: #000;

					p {
						color: #000;
					}
				}

				.owl-btn {
					display: none;
				}
			}
		}
	}

	.owl-nav {
		.owl-prev {
			@media(min-width: 1200px) {
				left: 40%;
			}
		}

		.owl-next {
			@media(min-width: 1200px) {
				right: 1%;
			}
		}
	}
}

.carousel {
	.owl-nav {
		.fa-chevron-right:before {
			content: "\f0a9";
			color: $blue-text;
		}

		.fa-chevron-left:before {
			content: "\f0a8";
			color: $blue-text;
		}
	}

	.owl-nav {
		@media(max-width: 991px) {
			display: none;
		}
	}

	.owl-dots {
		display: none;
	}
}
