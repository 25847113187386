// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;
	text-transform: none;
	font-size: $font-size-small;
	min-width: 242px;
	min-height: 48px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	// btn-primary
	&.btn-primary {
		color: #fff;
		background: $blue;
		border-color: $blue;

		&:hover {
			color: $blue;
			background: #fff;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		background: $blue-text;
		border-color: $blue-text;

		&:hover {
			background: #fff;
			color: $blue-text;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}


.arrow-right {
	&:after {
		content: '\f0a9';
		font-family: $font-awesome;
		margin-left: 15px;
		color: $blue-text;
	}
}
