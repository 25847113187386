// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;
	align-items: center;
	display: flex;
	@media(min-width: 1200px) {
		min-height: $headerheight;
	}

	&.sticky {
		box-shadow: $shadow;
	}

	[class^="container-"] {
		.container-holder {
			> .column {
				padding-right: 0;
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
	}

	// logo
	.logo {
		width: 100%;
		margin: 10px 10px 10px 115px;
		max-width: 223px;
		@media(max-width: 991px) {
			margin: 10px 10px 10px auto;
		}
		@media(max-width: 767px) {
			max-width: 106px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		font-size: $font-size-small;
		justify-content: flex-end;
		@include media-breakpoint-down(lg) {
			.dropdown-menu {
				position: static !important;
				transform: none !important;
				margin: 0 0 0 30px;
				padding: 0;
				border: none;
				background-color: transparent;
			}
		}

		@include media-breakpoint-up(xl) {

		}

		a {
			font-weight: bold;
			color: $blue-text;
		}

		.dropdown-menu {
			font-size: .9rem;
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: auto;
		@media(max-width: 767px) {
			margin-right: auto;
		}

		.dropdown-toggle {
			display: flex !important;
			align-items: center;
			justify-content: center;
		}
	}

	div.vocabulary-term-list {
		ul.vocabulary-term-list {
			li {
				a {
					@extend .btn;
					@extend .btn-primary;
					text-transform: none;
					padding: 0;
					padding-right: 20px;
					padding-left: 60px;
					height: 77px;
					display: inline-flex;
					align-items: center;
					border-radius: 50px 0 0 50px !important;
					margin-left: 25px;
					@media(max-width: 767px) {
						font-size: 0.813rem !important;
						min-width: 0 !important;
						margin-left: 0;
						padding-left: 24px;
						padding-right: 5px;
					}
				}
			}
		}
	}

	#default_menu {
		@media(max-width: 767px) {
			position: absolute;
			background: #fff;
			left: 0;
			top: 100%;
			width: 100%;
			padding: 0 25px;
		}
	}

	.navbar-toggler-icon {
		i {
			color: #0098CD !important;
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
