// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

&.default {
	background: url('~project/public/images/streepje.svg');
	background-attachment: fixed;
	background-color: #D6EADF;
	overflow-x: hidden;
}

&.home {
	@media(min-width: 1200px) {
		padding-top: $headerheight;
	}
}

h1, h2, h3, h4, h5, h6 {
	color: $blue-text;
}

.text-align-center {
	text-align: center;
}


.secondary-container {
	max-width: 1390px;
}

.mobile-hidden {
	@media(max-width: 767px) {
		display: none !important;
	}
}

.desktop-hidden {
	@media(min-width: 768px) {
		display: none !important;
	}
}

a[href*='tel:'],
a[href*='mailto:'] {
	text-decoration: none;
}

a[href*='tel:']:before,
a[href*='mailto:']:before {
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
	margin-right: 15px;
}

a[href*='tel:']:before {
	content: '\f879  ';

}

a[href*='mailto:']:before {
	content: '\f0e0 ';
}

.vocabulary-term-list {
	li {
		:not(:last-of-type) {
			padding-right: 15px;
		}

		i {
			margin-right: 5px;
		}
	}
}


