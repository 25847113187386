// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: transparent;
$green-dark: #92B6B1;
$blue: #0098CD;
$blue-dark: #0075BC;
$blue-text: #004066;
$white: #fff;
$black: #000;

// theme-colors
$primary: $blue-dark;
$secondary: $blue;
$tertiary: $blue;
$quaternary: $blue-dark;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$font-family-primary: 'Bitter', serif;
$font-family-secondary: 'Bitter', serif;
$font-family-tertiary: 'Open Sans', serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1.25rem; // 24px
$font-size-small: 1rem;
$font-size-med: 1.125rem;

$h1-font-size: 3.25rem; // 60px
$h2-font-size: 3.25rem; // 60px
$h3-font-size: 2.0rem; // 40px
$h4-font-size: 1.4rem; // 24px
$h5-font-size: 1.0rem; // 20px
$h6-font-size: $font-size-small; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $black;

// contextual
$light: $green;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;
$matrix-color-tertiary: $tertiary;
$matrix-color-quaternary: $quaternary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
*/
// grid containers
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1670px
) !default;

$headerheight: 115px;
