// new base file - january 2022

// reset base styling
// source: https://tommybookingsupport.com/widget/css/tommy.style.css
&.accommodation-detail .header .logo {
	width: revert;
}

&.accommodation-detail .main {
	padding: revert;
}

&.accommodation-detail .content-section ul,
&.accommodation-detail .content-section ol {
	padding: revert;
	list-style: revert;
}

// override base styling
// source: https://tommybookingsupport.com/widget/css/tommy.matrix.css

// colors
.tommy-matrix .the-matrix::after {
	background: linear-gradient(to right, rgba($matrix-color-primary, 0) 0%, rgba(#92B6B1, 1) 100%);
}

.tommy-matrix .matrix-bar legend {
	color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-bar label.active textarea,
.tommy-matrix .matrix-bar label.active input,
.tommy-matrix .matrix-bar label.active select {
	border-color: $black;
}

.tommy-matrix .matrix-bar .input-ok input,
.tommy-matrix .matrix-bar .input-ok select,
.tommy-matrix .matrix-bar label.input-ok::after {
	color: $black;
}

.tommy-matrix .the-matrix button,
.tommy-matrix .the-matrix .btn {
	background: $matrix-color-tertiary;
	border-color: $matrix-color-tertiary;
	box-shadow: none;
	color: $white;
}

.tommy-matrix .the-matrix button:hover,
.tommy-matrix .the-matrix .btn:hover {
	background: $matrix-color-secondary;
	border-color: $matrix-color-secondary;
	color: $white;
}

.tommy-matrix .the-matrix .popup .input-container_header {
	background: $matrix-color-tertiary;
	color: $white;
}

.tommy-matrix .the-matrix .popup:after {
	border-bottom-color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-bar .ul-nav li a:hover {
	color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-bar .ul-nav li a i {
	color: $matrix-color-tertiary;
}

.tommy-matrix .the-matrix .popup .show-col-table tr td {
	color: $matrix-color-tertiary;
}

.tommy-matrix .the-matrix .popup .show-col-table tr.td-total td {
	color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-bar .persons-table td a {
	color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-bar .persons-table td a:hover {
	color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-bar .persons-table td input:hover,
.tommy-matrix .matrix-bar .persons-table td input:focus {
	color: $matrix-color-tertiary;
}

.tommy-matrix .time-bar ul li a {
	color: $matrix-color-tertiary;
}

.tommy-matrix .time-bar .time-back,
.tommy-matrix .time-bar .time-ff {
	color: $matrix-color-secondary;

	&:hover {
		color: $matrix-color-tertiary;
	}
}

.tommy-matrix .time-bar .time-back {
	background: linear-gradient(to right, rgba(#92B6B1, 1) 0%, rgba($matrix-color-primary, 0) 100%);
}

.tommy-matrix .time-bar .time-ff {
	background: linear-gradient(to right, rgba($matrix-color-primary, 0) 0%, rgba(#92B6B1, 1) 100%);
}

.tommy-matrix .matrix-date-row .matrix-time i {
	color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-row.arrangement .row-arrang {
	background: rgba($white, 0.1);
	height: auto !important;
	width: calc(100vw - 25px);
	border-radius: 4px;
}

.tommy-matrix .matrix-row.arrangement .fixed-one {
	border-color: $black;
}

.tommy-matrix .row-arrang .title {
	color: $black;
	word-break: break-all;
	margin-right: 15px;
}
.tommy-matrix .row-arrang .date {
	margin-right: 15px;
}
.tommy-matrix .row-arrang .price {
	color: $black;
	white-space: nowrap;
}

.tommy-matrix .matrix-date-row .fixed-one {
	background: transparent;
}

.tommy-matrix .matrix-row .fixed-one .fixed-one_text {
	background: $matrix-color-secondary;
}

.tommy-matrix .matrix-row .fixed-one .fixed-one_text .more-info:hover {
	color: $matrix-color-secondary;
}

.tommy-matrix .the-matrix.periods .matrix-row .fixed-one .fixed-one_text {
	border-right-color: $matrix-color-secondary;
}

.tommy-matrix .matrix-beschikbaarheid {
	background: rgba($white, 0.9);
}

.tommy-matrix .matrix-beschikbaarheid .matrix-spec,
.tommy-matrix .matrix-beschikbaarheid .matrix-prijs,
.tommy-matrix .matrix-beschikbaarheid .matrix-trigger {
	color: $matrix-color-secondary;
}

.tommy-matrix .matrix-beschikbaarheid:hover {
	background: $matrix-color-secondary;
}

.tommy-matrix .matrix-beschikbaarheid:hover .matrix-spec,
.tommy-matrix .matrix-beschikbaarheid:hover .matrix-prijs,
.tommy-matrix .matrix-beschikbaarheid:hover .matrix-trigger {
	color: $white;
}

.tommy-matrix .matrix-beschikbaarheid .matrix-spec .vanvoor::after {
	border-color: $black;
}

.tommy-matrix .matrix-arrangement {
	background: $black;
}

.tommy-matrix .matrix-arrangement:hover .matrix-spec,
.tommy-matrix .matrix-arrangement:hover .matrix-prijs,
.tommy-matrix .matrix-arrangement:hover .matrix-trigger {
	color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-lastminute {
	background: $matrix-color-secondary;
	color: $white;
}

.tommy-matrix .matrix-lastminute:hover .matrix-spec,
.tommy-matrix .matrix-lastminute:hover .matrix-prijs,
.tommy-matrix .matrix-lastminute:hover .matrix-trigger {
	color: $matrix-color-tertiary;
}

.tommy-matrix .matrix-beschikbaarheid.no-data {
	background: rgba($white, 0.5);
}

// improvements
.tommy-matrix .the-matrix {
	font-size: 14px;
	font-family: $font-family-base;
}

.tommy-matrix .matrix-bar {
	margin: 0;
}

.tommy-matrix .matrix-scroll {
	padding: 0;
}

.tommy-matrix .matrix-bar label:not(.checkbox):not(.btn) {
	background: transparent;
}

.tommy-matrix .matrix-bar span.label-name {
	font-weight: 400;
}

.tommy-matrix .matrix-bar label > input,
.tommy-matrix .matrix-bar label > select,
.tommy-matrix .matrix-bar label > input {
	font-weight: 400;
	background: $white;
	box-shadow: $shadow;
}

.tommy-matrix .time-bar .time-back {
	padding-left: 0;
}

.tommy-matrix .time-bar .time-ff {
	padding-right: 0;
}

.tommy-matrix .matrix-date-row .matrix-time {
	font-size: 13px;
	font-weight: 700;
}

.tommy-matrix .the-matrix .matrix-beschikbaarheid.single-accommodation,
.tommy-matrix .the-matrix.periods .matrix-row .fixed-one .fixed-one_text {
	border-radius: 4px;
}

.tommy-matrix .the-matrix .matrix-beschikbaarheid.single-accommodation .vertical-align {
	height: 40px;
}

.tommy-matrix .the-matrix .popup .show-col-table tr td.lg {
	font-size: $font-size-lg;
}

@media (max-width: 768px) {
	/* pop-up fix */
	.tommy-matrix .the-matrix.periods .popup {
		width: auto;
		height: auto;
		max-width: 100% !important;
		top: 15px !important;
		left: 15px !important;
		right: 15px !important;
		bottom: inherit;
	}
}

.tooltipster-sidetip .tooltipster-box {
	background: $blue;
	border: 2px solid $blue-text;
	border-radius: 4px;
	font-size: 18px;

	.tooltipster-content {
		> div {
			margin-bottom: 5px;
		}
	}
}
